import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import { Box, Button, Container, Draw, Flex, Paragraph, Root } from 'garth';
import HelloWorld from './HelloWorld';
import Home from './Home';
import Components from './Components';
import LogIn from './LogIn';
import Compositions from './Compositions';
import Register from './Register';
import getArrayRandomItem from './getArrayRandomItem';
import './themes.css';
import navigationItems from './navigationItems';

const themes = ['default', 'gameboy', 'new-wave', 'vhs'];

const App = () => {
  const [theme, setTheme] = useState(themes[0]);
  const handleTheme = () => {
    setTheme(getArrayRandomItem(themes.filter((t) => t !== theme)));
  };
  return (
    <Router>
      <div className={theme}>
        <Root>
          <Container>
            <header>
              <Flex>
                <Box fluid passive />
                <Box passive>
                  <Draw>
                    <nav aria-label="Garth" role="navigation">
                      <Flex element="ul" wrap>
                        {navigationItems.map(({ label, path }) => (
                          <Box adaptive element="li" key={label}>
                            <Button element={Link} to={path}>
                              {label}
                            </Button>
                          </Box>
                        ))}
                        <Box adaptive element="li">
                          <Button type="button" onClick={handleTheme}>
                            Theme
                          </Button>
                        </Box>
                      </Flex>
                    </nav>
                  </Draw>
                </Box>
              </Flex>
            </header>
            <main>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/compositions">
                  <Compositions />
                </Route>
                <Route exact path="/components">
                  <Components />
                </Route>
                <Route exact path="/register">
                  <Register />
                </Route>
                <Route exact path="/log-in">
                  <LogIn />
                </Route>
                <Route exact path="/hello-world">
                  <HelloWorld />
                </Route>
                <Redirect to="/" />
              </Switch>
            </main>
            <footer>
              <Box>
                <Paragraph>&copy; Copyright.</Paragraph>
              </Box>
            </footer>
          </Container>
        </Root>
      </div>
    </Router>
  );
};

export default App;
