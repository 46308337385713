import {} from 'garth';

const code = `
<Container>
  <Box>
    <Title element="h1">Container</Title>
    <Paragraph>Contains content to a maximum width and centres content.</Paragraph>
  </Box>
</Container>
`;

export default {
  code,
  description: 'Contains content to a maximum width and centres content.',
  name: 'Container',
};
