import React from 'react';
import { Button, Box, Checkable, Container, Expand, Input, Label, Flex, Paragraph, Select, Title } from 'garth';
import { useForm } from 'react-hook-form';

const Register = () => {
  const { handleSubmit, register, watch } = useForm();
  const onSubmit = (data) => console.log(data);
  const values = watch(['gender']);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Box>
          <Title element="h1">Create a new account</Title>
          <Paragraph>It's quick and easy.</Paragraph>
        </Box>
        <Flex wrap>
          <Box adaptive width={6}>
            <Label htmlFor="firstName">First name</Label>
            <Input id="firstName" inputRef={register} name="firstName" />
          </Box>
          <Box adaptive width={6}>
            <Label htmlFor="lastName">Last name</Label>
            <Input id="lastName" inputRef={register} name="lastName" />
          </Box>
        </Flex>
        <Box>
          <Label htmlFor="email">Email</Label>
          <Input id="email" inputRef={register} name="email" />
        </Box>
        <Box>
          <Label htmlFor="password">Password</Label>
          <Input id="password" inputRef={register} name="password" type="password" />
        </Box>
        <Box>
          <Label htmlFor="birthday">Birthday</Label>
          <Input id="birthday" inputRef={register} name="birthday" placeholder="DD/MM/YYYY" />
        </Box>
        <Box>
          <Paragraph>
            ^ Providing your date of birth helps make sure that you get the right experience for your age. If you want
            to change who sees this, go to the About section of your Profile. For more details, please visit our Data
            Policy.
          </Paragraph>
        </Box>
        <Box>
          <Paragraph>
            Select a gender. If you'd rather not say, select Custom to choose another gender. You can change who sees
            your gender on your profile later.
          </Paragraph>
        </Box>
        <Flex wrap>
          <Box adaptive width={4}>
            <Checkable id="female" inputRef={register} name="gender" type="radio" value="female">
              Female
            </Checkable>
          </Box>
          <Box adaptive width={4}>
            <Checkable id="male" inputRef={register} name="gender" type="radio" value="male">
              Male
            </Checkable>
          </Box>
          <Box adaptive width={4}>
            <Checkable id="custom" inputRef={register} name="gender" type="radio" value="custom">
              Custom
            </Checkable>
          </Box>
        </Flex>
        <Expand expand={values.gender === 'custom'}>
          <Box>
            <Select
              disabled={values.gender !== 'custom'}
              name="pronoun"
              options={[
                {
                  label: 'Select your pronoun',
                  value: '',
                  props: {
                    defaultChecked: true,
                    ref: register,
                  },
                },
                {
                  label: 'She: "Wish her a happy birthday!"',
                  value: '1',
                  props: {
                    ref: register,
                  },
                },
                {
                  label: 'He: "Wish him a happy birthday!"',
                  value: '2',
                  props: {
                    ref: register,
                  },
                },
                {
                  label: 'They: "Wish them a happy birthday!"',
                  value: '6',
                  props: {
                    ref: register,
                  },
                },
              ]}
            />
          </Box>
          <Box>
            <Paragraph>^ Your pronoun is visible to everyone.</Paragraph>
          </Box>
          <Box>
            <Label htmlFor="customGender">Gender (optional)</Label>
            <Input disabled={values.gender !== 'custom'} id="customGender" inputRef={register} name="customGender" />
          </Box>
        </Expand>
        <Box>
          <Paragraph>
            By clicking <strong>Sign up</strong>, you agree to our Terms, Data Policy and Cookie Policy. You may receive
            SMS notifications from us and can opt out at any time.
          </Paragraph>
        </Box>
        <Flex>
          <Box adaptive width={6}>
            <Button type="submit">Sign up</Button>
          </Box>
        </Flex>
      </Container>
    </form>
  );
};

export default Register;
