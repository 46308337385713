import React from 'react';
import { Select } from 'garth';

const code = `
<Select
  name="options"
  options={[
    {
      label: 'Select',
      value: '',
      props: {
        defaultChecked: true,
      },
    },
    {
      label: 'Option 1',
      value: '1',
      props: {},
    },
    {
      label: 'Option 2',
      value: '2',
      props: {},
    },
  ]}
/>
`;

const component = () => (
  <Select
    name="options"
    options={[
      {
        label: 'Select',
        value: '',
        props: {
          defaultChecked: true,
        },
      },
      {
        label: 'Option 1',
        value: '1',
        props: {},
      },
      {
        label: 'Option 2',
        value: '2',
        props: {},
      },
    ]}
  />
);

export default {
  code,
  component,
  description:
    'Experimental component. Select built from styled radio buttons. Input ref can be passed with props object.',
  name: 'Select',
};
