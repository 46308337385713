import React from 'react';
import { Anchor, Button, Border, Box, Container, Flex, Paragraph, Title } from 'garth';
import Code from './Code';

const Home = () => {
  return (
    <div>
      <Container>
        <Box>
          <Title element="h1">Garth</Title>
          <Paragraph>
            Responsive, mobile-first user interface components for <Anchor href="https://reactjs.org/">React</Anchor>.
          </Paragraph>
          <Paragraph>Garth is a user interface library by Garth for Garth.</Paragraph>
        </Box>
        <Box>
          <Border>
            <Box>
              <Paragraph>
                <strong>Note</strong> — Garth documentation is a work in progress.
              </Paragraph>
            </Box>
          </Border>
        </Box>
        <Box>
          <Code code="npm install --save garth" language="bash" />
        </Box>
        <Flex wrap>
          <Box adaptive width={6}>
            <Button element="a" href="https://www.npmjs.com/package/garth" rel="noopener noreferrer" target="_blank">
              View package on NPM
            </Button>
          </Box>
          <Box adaptive width={6}>
            <Button
              element="a"
              href="https://github.com/garthmcrae/react-components"
              rel="noopener noreferrer"
              target="_blank"
            >
              View package on GitHub
            </Button>
          </Box>
        </Flex>
        <Box>
          <Title>Built using Garth</Title>
        </Box>
        <Box>
          <Button element="a" href="http://sessionprodrinker.com/" rel="noopener noreferrer" target="_blank">
            Session pro drinker
          </Button>
        </Box>
        <Box>
          <Button element="a" href="https://www.drinkingtherpg.com/" rel="noopener noreferrer" target="_blank">
            Drinking the RPG
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default Home;
