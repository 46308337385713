import {} from 'garth';

const code = `
<Root>
  ...
</Root>
`;

export default {
  code,
  description:
    'Applies variable defaults. Not required if defaults are specified within your application index stylesheet.',
  name: 'Root',
};
