import React from 'react';
import { Anchor, Border, Button, Box, Container, Flex, Paragraph, Rule, Title } from 'garth';

const HelloWorld = () => {
  return (
    <Container>
      <Box>
        <Title element="h1">Hello World</Title>
        <Paragraph>The smallest React example looks like this:</Paragraph>
      </Box>
      <Box>
        <pre style={{ whiteSpace: 'initial', wordBreak: 'break-all' }}>
          <code>ReactDOM.render(&lt;h1&gt;Hello, world!&lt;/h1&gt;,document.getElementById('root'));</code>
        </pre>
      </Box>
      <Flex>
        <Box adaptive width={6}>
          <Button element="a" href="https://codesandbox.io/s/garth-ui5s4" rel="noopener noreferrer" target="_blank">
            See some more content
          </Button>
        </Box>
      </Flex>
      <Box>
        <Paragraph>
          Click the link above to open an online editor. Feel free to make some changes, and see how they affect the
          output. Most pages in this guide will have editable examples like this one.
        </Paragraph>
      </Box>
      <Box>
        <Rule />
      </Box>
      <Box>
        <Title>How to Read This Guide</Title>
      </Box>
      <Box>
        <Paragraph>
          In this guide, we will examine the building blocks of React apps: elements and components. Once you master
          them, you can create complex apps from small reusable pieces.
        </Paragraph>
      </Box>
      <Box>
        <Border>
          <Box>
            <Paragraph>
              <strong>Tip</strong> — This guide is designed for people who prefer{' '}
              <strong>learning concepts step by step</strong>. If you prefer to learn by doing, check out our{' '}
              <Anchor href="/tutorial/tutorial.html">practical tutorial</Anchor>. You might find this guide and the
              tutorial complementary to each other.
            </Paragraph>
          </Box>
        </Border>
      </Box>
      <Box>
        <Paragraph>
          This is the first chapter in a step-by-step guide about main React concepts. You can find a list of all its
          chapters in the navigation sidebar. If you’re reading this from a mobile device, you can access the navigation
          by pressing the button in the bottom right corner of your screen.
        </Paragraph>
        <Paragraph>
          Every chapter in this guide builds on the knowledge introduced in earlier chapters.{' '}
          <strong>
            You can learn most of React by reading the “Main Concepts” guide chapters in the order they appear in the
            sidebar.
          </strong>{' '}
          For example, <Anchor href="/docs/introducing-jsx.html">“Introducing JSX”</Anchor> is the next chapter after
          this one.
        </Paragraph>
      </Box>
      <Box>
        <Rule />
      </Box>
      <Box>
        <Title>Let’s Get Started!</Title>
      </Box>
      <Box>
        <Paragraph>
          Keep scrolling down, and you’ll find the link to the{' '}
          <Anchor href="/docs/introducing-jsx.html">next chapter of this guide</Anchor> right before the website footer.
        </Paragraph>
      </Box>
    </Container>
  );
};

export default HelloWorld;
