import React from 'react';
import { Anchor, Button, Box, Container, Flex, Input, Label, Paragraph, Title } from 'garth';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
// import Thought from 'thought-bubble';

const LogIn = () => {
  const { handleSubmit, register } = useForm();
  const onSubmit = (data) => console.log(data);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Box>
          <Title element="h1">Log in to existing account</Title>
        </Box>
        <Box>
          <span style={{ display: 'block', position: 'relative' }}>
            <Label htmlFor="email">Email</Label>
            {/* <Thought>Used to log in and also if we need to get in touch.</Thought> */}
          </span>
          <Input id="email" inputRef={register} name="email" />
        </Box>
        <Box>
          <span style={{ display: 'block', position: 'relative' }}>
            <Label htmlFor="password">Password</Label>
            {/* <Thought>Length is greater than complexity why not use an easy to remember sentence?</Thought> */}
          </span>{' '}
          <Input id="password" inputRef={register} name="password" type="password" />
        </Box>
        <Box>
          <Paragraph>
            <Anchor element={Link} to="/">
              Forgotten account?
            </Anchor>
          </Paragraph>
        </Box>
        <Flex>
          <Box adaptive width={6}>
            <Button type="submit">Log in</Button>
          </Box>
        </Flex>
      </Container>
    </form>
  );
};

export default LogIn;
