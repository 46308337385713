const items = [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'Components',
    path: '/components',
  },
  {
    label: 'Compositions',
    path: '/compositions',
  },
];

export default items;
